<template>
  <v-app-bar app color="#e7dbcf">
    <v-img class="logo" src="../assets/logo.svg"></v-img>
    <!-- <h1 class="logo">The Whitaker Wedding</h1> -->

    <v-tabs right hide-slider class="d-md-block d-sm-none d-none">
      <v-tab href="#hero" @click="$vuetify.goTo('#hero')">Home</v-tab>
      <v-tab href="#our-story" @click="$vuetify.goTo('#our-story')"
        >Our Story</v-tab
      >
      <v-tab href="#wedding-party" @click="$vuetify.goTo('#wedding-party')"
        >Wedding Party</v-tab
      >
      <v-tab href="#travel" @click="$vuetify.goTo('#travel')">Travel</v-tab>
      <v-tab href="#registry" @click="$vuetify.goTo('#registry')"
        >Registry</v-tab
      >
      <v-tab href="#faqs" @click="$vuetify.goTo('#faqs')">FAQs</v-tab>
      <v-tab href="#rsvp" @click="$vuetify.goTo('#rsvp')">RSVP</v-tab>
    </v-tabs>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style scoped>
.logo {
  max-height: 50px;
  width: 100%;
  max-width: 25rem;
}
.v-tab {
  font-size: 0.75rem !important;
  color: #403730 !important;
}
</style>
