<template>
  <div id="our-story">
    <v-container style="max-width: 75rem">
      <img class="heading" src="../assets/our-story-text.svg" />

      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item fill-dot small color="#8c7664" class="align-center">
          <template v-slot:opposite>
            <v-img src="../assets/chicograd.jpg" class="elevation-2"></v-img>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="text-h5"
              >It all started at Chico State...</v-card-title
            >
            <v-card-text>
              We met in college while rock climbing at the Chico State WREC. We
              were both regulars and one day we got to talking and hit it off!
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item fill-dot small color="#8c7664" class="align-center">
          <template v-slot:opposite>
            <v-img
              src="../assets/groupbishop-crop.jpg"
              class="elevation-2"
            ></v-img>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="text-h5">Climbing Trips</v-card-title>
            <v-card-text>
              Some of our most fun times were the amazing climbing trips we went
              on together and with our friends! From Labor Day in Bishop, to
              Friendsgiving in Yosemite, we are so thankful for our awesome
              friends and the memories we've made together.
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item fill-dot small color="#8c7664" class="align-center">
          <template v-slot:opposite>
            <v-img src="../assets/rmnp.jpg" class="elevation-2"></v-img>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="text-h5">Off to Colorado</v-card-title>
            <v-card-text>
              After graduating from Chico State, we moved to Fort Collins,
              Colorado. Alyssa pursued a Masters of Science in Alpine Hydrology
              while Tim started building his career as a freelance software
              engineer!
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item fill-dot small color="#8c7664" class="align-center">
          <template v-slot:opposite>
            <v-img src="../assets/poudrecanyon.jpg" class="elevation-2"></v-img>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="text-h5">The Adventures Continue</v-card-title>
            <v-card-text>
              Colorado is the mecca for rock climbing. Needless to say, we spent
              a lot of time climbing and traveling around the western United
              States. Along with beautiful destinations in Colorado, our
              adventures took us to Arizona, Nevada, New Mexico, Utah, and
              Wyoming.
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item fill-dot color="#8c7664" small class="align-center">
          <template v-slot:opposite>
            <v-img src="../assets/proposal.jpg" class="elevation-2"></v-img>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="text-h5">The Proposal</v-card-title>
            <v-card-text>
              We'd been together for 5 years and knew for a long time that we
              wanted to marry each other. On one of our holiday trips to our
              favorite climbing area in the world, Tim surprised Alyssa with a
              freezing, but heartfelt proposal above the Happy Boulders.
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item fill-dot color="#8c7664" small class="align-center">
          <template v-slot:opposite>
            <v-img
              src="../assets/basslake-portrait.jpg"
              class="elevation-2"
            ></v-img>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="text-h5"
              >Introducing The Whitakers</v-card-title
            >
            <v-card-text>
              The past two years brought many changes to our lives, including
              moving back to California. Alyssa began her career as an
              Environmental Scientist for the State of California while Tim went
              back to school to pursue a PhD in Machine Learning. We can't wait
              to get married this May and are we excited to start this new
              chapter of our lives!
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#our-story {
  background: #e7dbcf;
  padding: 5rem 0;
}
.heading {
  padding-bottom: 5rem;
  margin: 0 auto;
  display: block;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
  max-width: 300px;
  width: 90vw;
}
.v-card {
  background: #8c7664 !important;
  color: #fbede0 !important;
}
.v-card__title {
  font-family: "Playfair Display" !important;
}
.v-card__text {
  color: #fbede0 !important;
}

.v-timeline-item__inner-dot {
  background: #8c7664 !important;
}

.v-timeline-item__body > .v-card:not(.v-card--flat)::after {
  border-right-color: #8c7664;
}
</style>