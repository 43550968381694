<template>
  <div id="thanks">
    <v-container class="thanks-container">
      <h1>Thank you!</h1>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#thanks {
  padding: 5rem 0;
  color: #fffbf6;
  background: #868d85;
  text-align: center;

  h1 {
    font-family: "Crimson Foam";
    font-size: 10rem;
    font-weight: normal;
    line-height: 1;
  }
}
</style>