<template>
  <div id="rsvp">
    <img class="heading" src="../assets/rsvp-text.svg" />
    <div class="intro-message">
      <p>
        We're so excited to celebrate with you! Please enter the name of one
        member of your party below. You will be able to RSVP for you entire
        group.
      </p>
    </div>

    <div class="search-container">
      <VueFuse
        :list="guests"
        :fuse-opts="options"
        :defaultAll="false"
        :map-results="false"
        placeholder="Name"
        @fuse-results="handleResults"
        @keyup.enter.native="searchGuests"
      />
      <v-btn @click="searchGuests" class="Search Button">Search</v-btn>
    </div>

    <div v-if="showResults">
      <p class="message">
        We found the following invitations related to your search. Please click
        your invite and confirm all guests status.
      </p>
      <div v-for="(guest, i) in results" class="list-group" :key="i">
        <!-- list items from the result array -->
        <span class="invite-names" @click="setActiveRSVP(guest.item)"
          >{{ guest.item.names.slice(0, 2).join(" and ")
          }}<template v-if="guest.item.names.length > 2"> and Family</template>
        </span>
      </div>
    </div>

    <div :class="{ hidden: !activeRSVP }">
      <div class="rsvp-container">
        <v-simple-table class="invite-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Response</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(name, i) in activeRSVP.names"
              :key="i"
              class="rsvp-container"
            >
              <td>
                <template v-if="name === 'Family'">
                  <input
                    type="text"
                    class="extra-names"
                    placeholder="Please Enter Additional Guests (Children)"
                    v-model="extraGuests"
                  />
                </template>
                <template v-else>
                  <h2 class="guest-name">{{ name }}</h2>
                </template>
              </td>
              <td>
                <div class="button-group">
                  <v-btn
                    v-bind:class="{ active: isAccepted(name) }"
                    @click="acceptInvite(name)"
                    >Accept</v-btn
                  >
                  <v-btn
                    v-bind:class="{ active: isDeclined(name) }"
                    @click="declineInvite(name)"
                    >Decline</v-btn
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <br />
        <v-text-field
          label="Dietary restrictions?"
          v-model="restrictions"
        ></v-text-field>
        <v-text-field
          label="Additional Comments or Questions?"
          v-model="comments"
        ></v-text-field>

        <v-form
          class="form"
          id="netlify-rsvp"
          name="RSVP"
          method="POST"
          v-on:submit.prevent="handleSubmit"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="RSVP" />

          <v-text-field name="response" class="hidden"></v-text-field>
          <v-btn class="submit-button" type="submit">Submit</v-btn>
        </v-form>
      </div>
    </div>

    <div v-if="formSent" class="form-sent">
      <p>Thank you for submitting your RSVP!</p>
    </div>
  </div>
</template>

<script>
import VueFuse from "vue-fuse/src/components/VueFuse";

export default {
  components: { VueFuse },
  data() {
    return {
      results: [],
      showResults: false,
      activeRSVP: false,
      accepted: [],
      declined: [],
      formData: {},
      extraGuests: "",
      formSent: false,
      restrictions: "",
      comments: "",
      guests: [
        { names: ["Brian Whitaker", "Sandy Whitaker"] },
        { names: ["Pat Whitaker"] },
        { names: ["Dylan Whitaker"] },
        { names: ["Les Morris"] },
        { names: ["Leslie Whitaker"] },
        { names: ["Jim Flippo", "Lana Flippo"] },
        { names: ["Makenna Flippo", "Family"] },
        { names: ["Jaymi Velazco", "Edgar Velazco", "Family"] },
        { names: ["Kimberlei Flippo", "Family"] },
        {
          names: [
            "Lynn Whitaker",
            "Brad Whitaker",
            "Sam Whitaker",
            "Tom Whitaker",
          ],
        },
        {
          names: [
            "Brooke Bright",
            "Gina Bright",
            "Olivia Bright",
            "Matthew Burton",
            "Charles Bright",
            "Sean Bright",
            "Shelby Tilley",
            "Isabella Bright",
          ],
        },
        { names: ["Genevieve Winners", "Matt Winners"] },
        { names: ["Steve Province", "Stephanie Province"] },
        { names: ["Melissa Baca"] },
        { names: ["Pam Babich"] },
        { names: ["Marty Michaud", "Ella McCullum"] },
        { names: ["Taylor Michaud", "Eileen Michaud"] },
        { names: ["Ron Tate", "Linda Tate"] },
        {
          names: [
            "Andrew Rothberg",
            "Brandi Tate",
            "Mason Rothberg",
            "Dylan Rothberg",
          ],
        },
        { names: ["Kyler Newton", "Kayla Knight"] },
        { names: ["Tom Newton", "Jan Newton"] },
        { names: ["Kate Newton", "Thomas Correll"] },
        { names: ["Austin Crouch", "Morgan Kissee"] },
        { names: ["Logan Jones", "Brittany Stephens"] },
        { names: ["Mike Anderson"] },
        { names: ["Greg Kessing"] },
        { names: ["Kevin Martin", "Britany Steger-Martin"] },
        { names: ["Pablo Velasco", "Carly Shields"] },
        { names: ["Sam Wallace", "Stephanie Wallace"] },
        { names: ["Ryan Kocsis"] },
        { names: ["Geneva Desin"] },
        { names: ["Zach Probert", "Mara Mabugat"] },
        { names: ["Jacob Jones", "Lily Mirsepassi"] },
        { names: ["Tucker Thompson", "Julissa Otzoy-Medrano"] },
        { names: ["Devon Rhodes", "Kristine Holmstedt"] },
        { names: ["Nick Davis", "Ashley McNally"] },
        { names: ["Riley Cox", "Natasha Burns"] },
        { names: ["Noah Chavez", "Jessica Weber"] },
        { names: ["Spencer Hemstreet", "Camille Uzel"] },
        { names: ["Mama Josefina Cortez"] },
        { names: ["Dana Anenberg", "Martha Anenberg"] },
        { names: ["Bryan Anenberg", "Melissa Wibowo"] },
        { names: ["Sharon Annenberg"] },
        { names: ["Steven Apodaca", "Angelica Apodaca"] },
        { names: ["Stephanie Apodaca", "Alec Apodaca"] },
        { names: ["Andrew Apodaca", "Alexis Apodaca"] },
        { names: ["Manuel Tinoco", "Lupe Tinoco"] },
        { names: ["Manny Tinoco"] },
        { names: ["Eduardo Tinoco", "Danielle Bacon"] },
        { names: ["Jose Castro", "Salud Castro"] },
        { names: ["Adan Castro", "Tiffanie Castro"] },
        { names: ["Robert Castro", "Julie Shepherd"] },
        { names: ["Elizabeth Castro", "Ivy Babatid"] },
        {
          names: [
            "Ana Salcedo",
            "Armando Salcedo",
            "Avani Salcedo",
            "Nathan Wilson",
            "Anissa Salcedo",
            "Annalyse Salcedo",
          ],
        },
        { names: ["Frank Zintzun", "Mary Zintzun", "Aaron Zintzun"] },
        {
          names: [
            "Robert Pier",
            "Alicia Pier",
            "Sebastian Pier",
            "Bryanna Pier",
            "Lucas Pier",
          ],
        },
        {
          names: [
            "Temo Zintzun",
            "Maggie Zintzun",
            "Kyle Zintzun",
            "Christian Zintzun",
          ],
        },
        { names: ["Jon Jimeno", "Annette Jimeno"] },
        { names: ["Raymon Zintzun"] },
        { names: ["Ricardo Zintzun", "Giselle Zintzun", "Family"] },
        { names: ["Teresa Zintzun", "Abraham Gomez"] },
        { names: ["Brett Keating", "Melanie Keating", "Aidan Keating"] },
        { names: ["Jonathan Keating", "Sheena Ferguson"] },
        { names: ["Emily Keating", "Paul Bailleul"] },
        { names: ["Morgan Thomas", "Travis Bernard"] },
        { names: ["Nicole Peterson", "Keith Omundson"] },
        { names: ["Elizabeth Chung", "Samuel Jones"] },
        { names: ["Katherine Hanson"] },
        { names: ["Erin Griffin-Bishop", "Marty Bishop"] },
        { names: ["Tyler Pebley", "Leanna Pebley"] },
        { names: ["Danielle Reff", "Andrew Gold"] },
        { names: ["Danielle DiCarlo", "Patrick Vega"] },
        { names: ["Robyn Beisswanger-Cordeiro", "Kevin Cordeiro"] },
        { names: ["Sandrine Matiasek", "Mike Matiasek"] },
        { names: ["Steven Skorupa", "Esteban Montano"] },
        { names: ["Anna Pfohl"] },
        { names: ["Eric Hassel", "Emily Tallchief"] },
        { names: ["Jerry Levy", "Joanne Levy"] },
        { names: ["Andrew Schnee", "Stephanie Schnee"] },
        { names: ["Annie Schnee"] },
        { names: ["Don Ketchum", "Sandy Ketchum"] },
        { names: ["Howard Schnee", "Karen Schnee"] },
        { names: ["Elliot Schnee", "Kathy Schnee"] },
        { names: ["Mardiana Misjuwar", "John Fiegel"] },
        { names: ["Eric Horwitz", "Janet Horwitz"] },
        { names: ["Linda Coss", "Joe Kaplan"] },
        { names: ["Debbie Spielman"] },
        { names: ["Bobbi Arakelian"] },
        { names: ["Dave Cooper", "Tammy Cooper"] },
        { names: ["Kara Jacobs"] },
        { names: ["Kristine Clark"] },
        { names: ["Paige Siniscalchi"] },
        { names: ["Raymon Zintzun", "Aida Ariaei"] },
        { names: ["Tony Caruso", "Dora Caruso", "Margot Caruso"] },
        { names: ["Darrell Whitley", "Beth Thurston"] },
        { names: ["Michael Benedetti", "Jodee Benedetti"] },
        { names: ["Jonathan Pham", "Adriana Sanchez"] },
      ],
      opts: {
        keys: ["names"],
      },
    };
  },
  computed: {
    options() {
      return {
        keys: ["names"],
        includeScore: this.includeScore,
        minMatchCharLength: 2,
        threshold: 0.2,
      };
    },
  },
  methods: {
    handleResults(r) {
      this.results = r;
    },
    handleSubmit(e) {
      // build form data
      this.formData = {
        accepted: this.accepted,
        declined: this.declined,
        extraGuests: this.extraGuests,
        restrictions: this.restrictions,
        comments: this.comments,
      };

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": e.target.getAttribute("name"),
          response: JSON.stringify(this.formData),
        }),
      })
        .then(() => {
          this.formSent = true;
          this.activeRSVP = false;
        })
        .catch((error) => alert(error));
    },
    searchGuests() {
      this.showResults = true;
      this.formSent = false;
    },
    setActiveRSVP(guest) {
      this.activeRSVP = guest;
      this.showResults = false;
    },
    isAccepted(name) {
      return this.accepted.includes(name);
    },
    isDeclined(name) {
      return this.declined.includes(name);
    },
    acceptInvite(name) {
      if (!this.accepted.includes(name)) {
        this.accepted.push(name);

        if (this.declined.includes(name)) {
          this.declined.splice(this.declined.indexOf(name), 1);
        }
      }
    },
    declineInvite(name) {
      if (!this.declined.includes(name)) {
        this.declined.push(name);

        if (this.accepted.includes(name)) {
          this.accepted.splice(this.accepted.indexOf(name), 1);
        }
      }
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    },
  },
};
</script>

<style lang="scss" scoped>
#rsvp {
  padding: 5rem 0;
  text-align: center;
  background: #e7dbcf;

  p {
    color: #403730;
  }
  .heading {
    padding-bottom: 3rem;
    margin: 0 auto;
    display: block;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
    max-width: 200px;
    width: 90vw;
  }
  input {
    background: white;
  }
  .message {
    padding-top: 1rem;
  }
  .invite-names {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
    // color: #1976d2;
    color: #376797;
    font-family: "Playfair Display";

    &:hover {
      color: #1c3248;
    }
  }
  .search-container {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      height: 36px;
      margin-right: 20px;
      border-radius: 4px;
      padding: 10px;
    }
    button {
      background-color: #382c2c !important;
      color: #e7dbcf !important;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .rsvp-container {
    max-width: 50rem;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 3rem;

    td,
    th {
      color: #403730;
      text-align: left;
    }

    td:first-child,
    th:first-child {
      width: 99%;
    }

    th {
      font-size: 1rem;
      font-weight: normal;
    }

    button {
      margin-right: 10px;
    }
    .active {
      background: #376797;
      color: white;
    }
    .submit-button {
      margin-top: 10px;
    }
  }

  .invite-table.theme--light.v-data-table {
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.35);
    background: none;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgba(0, 0, 0, 0.1);
  }

  .guest-name {
    font-family: "Playfair Display" !important;
  }

  .intro-message {
    max-width: 40rem;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 2rem;
  }

  .form-sent {
    padding-top: 3rem;
  }

  .hidden {
    display: none;
  }
  .extra-names {
    height: 36px;
    padding: 10px;
    border-radius: 4px;
    min-width: 80%;
  }
}
</style>
