import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=1541bd48&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1541bd48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VParallax } from 'vuetify/lib/components/VParallax';
installComponents(component, {VOverlay,VParallax})
