import Vue from "vue"
import Vuex from "vuex"
import VueRouter from "vue-router"
import App from "./App.vue"
import Login from "./Login.vue"
import Home from "./Home.vue"
import vuetify from "./plugins/vuetify"

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    authenticated: false,
  },
  mutations: {
    setAuthentication(state, status) {
      state.authenticated = status
    },
  },
})

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: {
        name: "login",
      },
    },
    {
      path: "/#/",
      redirect: {
        name: "login",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      beforeEnter: (to, from, next) => {
        if (store.state.authenticated == false) {
          next(false)
        } else {
          next()
        }
      },
    },
  ],
})

new Vue({
  vuetify,
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount("#app")
