<template>
  <div id="registry">
    <v-container class="registry-container">
      <img class="heading" src="../assets/registry-text.svg" />

      <p>
        What we want most for our wedding is to have all of our friends and
        family together to celebrate with us. We’re looking forward to this next
        chapter of our lives, and to build a home and a family together.
      </p>

      <v-btn
        href="https://www.zola.com/registry/whitakerwedding2022"
        color="white"
        >View Our Registry</v-btn
      >
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#registry {
  color: white;
  padding: 5rem 0;
  text-align: center;
  background: #e7dbcf;

  .heading {
    padding-bottom: 5rem;
    margin: 0 auto;
    display: block;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
    max-width: 300px;
    width: 90vw;
  }

  p {
    color: #382c2c;
    max-width: 40rem;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 5rem;
  }

  .v-btn {
    background-color: #382c2c !important;
    color: #e7dbcf !important;
  }
}
</style>