<template>
  <div id="wedding-party">
    <img class="heading" src="../assets/party-text.svg" />
    <v-container>
      <v-row>
        <v-spacer></v-spacer>

        <v-col
          v-for="(person, i) in row1"
          :key="i"
          class="col-md-2 col-sm-6 col-12"
        >
          <v-hover v-slot="{ hover }">
            <v-img :src="person.image" aspect-ratio="1">
              <v-fade-transition>
                <v-img
                  v-if="hover"
                  class="transition-fast-in-fast-out"
                  :src="person.hover"
                  aspect-ratio="1"
                >
                </v-img>
              </v-fade-transition>
            </v-img>
          </v-hover>

          <h3>{{ person.name }}</h3>
          <p>{{ person.title }}</p>
          <p>{{ person.bio }}</p>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          v-for="(person, i) in row2"
          :key="i"
          class="col-md-2 col-sm-6 col-12"
        >
          <v-hover v-slot="{ hover }">
            <v-img :src="person.image" aspect-ratio="1">
              <v-fade-transition>
                <v-img
                  v-if="hover"
                  class="transition-fast-in-fast-out"
                  :src="person.hover"
                  aspect-ratio="1"
                >
                </v-img>
              </v-fade-transition>
            </v-img>
          </v-hover>

          <h3>{{ person.name }}</h3>
          <p>{{ person.title }}</p>
          <p>{{ person.bio }}</p>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    let people = [
      {
        name: "Dylan",
        title: "Best Man",
        bio: "Dylan is Tim's younger brother and loves to go on hikes, play video games, and party at music festivals! Dylan is a technical wizard with computers, electronics, and strategy games, and is always coming up with great ideas for new inventions. Tim and Dylan share fond memories going on vacations, snowboarding, dirtbiking, boating and swimming with sharks.",
        image: require("../assets/wedding-party/dylan1.jpg"),
        hover: require("../assets/wedding-party/dylan2.jpg"),
      },
      {
        name: "Emily",
        title: "Maid of Honor",
        bio: "Teacher, fashion designer, world traveler… is there anything Emily can’t do? Emily and Alyssa grew up only two houses apart and share memories of playing with makeup, finding the best hide-and-seek spots in the neighborhood, and going on summer camping trips with their families. Fun fact: our friendship has spanned 25 years and currently 9 time zones!",
        image: require("../assets/wedding-party/emily1.jpg"),
        hover: require("../assets/wedding-party/emily2.jpg"),
      },
      {
        name: "Kyler",
        title: "Groomsman",
        bio: "Kyler and Tim have been best friends for the past fifteen years. They met the summer before freshman year of high school and have been getting into trouble ever since! Kyler is a master brewer and he loves to mountain bike and throw gainers off 50+ foot bridges. Don't even think about challenging him to horseshoes or darts!",
        image: require("../assets/wedding-party/kyler1.jpg"),
        hover: require("../assets/wedding-party/kyler2.jpg"),
      },
      {
        name: "Morgan",
        title: "Bridesmaid",
        bio: "Morgan’s a west coast gal turned Midwestern. Alyssa and Morgan met in middle school, and while their excessive eyeliner (fortunately) did not stand the test of time, their friendship did. Morgan’s an incredible dancer and a badass with a motorcycle. She’s also a fan of early morning coffee, anime and MTG, and hanging out with her two cats.",
        image: require("../assets/wedding-party/morgan1.jpg"),
        hover: require("../assets/wedding-party/morgan2.jpg"),
      },
      {
        name: "Bryan",
        title: "Groomsman",
        bio: "Bryan is Alyssa's older brother and she's always looked up to him. Bryan is a machine learning engineer for a hot tech startup, where he is teaching robots to see. In his free time he enjoys biking, golfing, and training Brazilian Jiu-Jitsu. Tim has had a ton of fun trying to keep up with Bryan on some of the incredible mountain bike trails along the coast of Southern California!",
        image: require("../assets/wedding-party/bryan1.jpg"),
        hover: require("../assets/wedding-party/bryan2.jpg"),
      },
      {
        name: "Stephanie",
        title: "Bridesmaid",
        bio: "When Stephanie and Alyssa are together, you can guarantee that there’s going to be wine, bad dancing, and lots of pictures. But we wouldn’t have it any other way! Not only is Steph Alyssa’s cousin, she’s also an incredible mom to her son Alec and two rescue pitties, a healthcare worker, and a houseplant guru.",
        image: require("../assets/wedding-party/steph1.jpg"),
        hover: require("../assets/wedding-party/steph2.jpg"),
      },
      {
        name: "Logan",
        title: "Groomsman",
        bio: "Tim and Logan hit the jackpot when they were randomly assigned as roommates in the dorms at Chico State. They quickly became great friends and bonded over jamming out on guitar, skating around town, and playing video games. Logan's voice is like a mixture of Fergie and Jesus and he can outplay almost anyone in call of duty and beer-io kart.",
        image: require("../assets/wedding-party/logan1.jpg"),
        hover: require("../assets/wedding-party/logan2.jpg"),
      },
      {
        name: "Nicole",
        title: "Bridesmaid",
        bio: "Nicole and Alyssa met long, long ago in elementary school and share a love for climbing, the outdoors, late night boba tea excursions, Del Taco, and laughing way too hard at literally everything. Nicole is a senior analyst for a think tank and she is always down for an adventure with her fiance and her sweet rescue pup, Maya!",
        image: require("../assets/wedding-party/nicole1.jpg"),
        hover: require("../assets/wedding-party/nicole2.jpg"),
      },

      {
        name: "Kevin",
        title: "Groomsman",
        bio: "Kevin and Tim met the summer before high school and instantly became best friends. From wakeboarding to video games, to computer science and skateboarding, their list of shared interests goes on and on. Kevin is a proud dad to a new baby boy and is absolutely killer at the kendama!",
        image: require("../assets/wedding-party/kevin1.jpg"),
        hover: require("../assets/wedding-party/kevin2.jpg"),
      },
      {
        name: "Katie",
        title: "Bridesmaid",
        bio: "Katie and Alyssa met in first grade and have been friends ever since. She’s an elementary school teacher in Oregon but her real passion lies in scream-singing to Taylor Swift and going to concerts! When she’s not teaching, you can find her with a drink in hand, cuddling with her cat “Meas”, or reading a good book in a cozy coffee shop.",
        image: require("../assets/wedding-party/katie1.jpg"),
        hover: require("../assets/wedding-party/katie2.jpg"),
      },
    ];

    return { row1: people.slice(0, 5), row2: people.slice(5, 10) };
  },
};
</script>

<style lang="scss" scoped>
#wedding-party {
  background: #e7dbcf;
  padding: 10rem 0;

  .title {
    text-align: center;
    padding-bottom: 7rem;
    font-family: "Wild Mango" !important;
    font-size: 4rem !important;
    color: #403730;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  }

  .heading {
    padding-bottom: 5rem;
    margin: 0 auto;
    display: block;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
    max-width: 500px;
    width: 90vw;
  }

  h3 {
    margin-top: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
  }

  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
  .container {
    max-width: 100vw;
  }
}
</style>
<style>
@media screen and (max-width: 960px) {
  .v-timeline-item {
    flex-wrap: wrap;
  }
  .v-timeline-item__opposite {
    display: block !important;
    max-width: calc(100% - 96px);
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.v-card__title {
  word-break: break-word;
}
</style>