<template>
  <div class="gallery">
    <div class="grid">
      <div class="grid-sizer"></div>
      <div v-for="(image, i) in images" :key="i" class="grid-item">
        <img
          :src="image"
          @click="
            overlay = !overlay;
            overlayimg = image;
          "
        />
      </div>

      <v-overlay :value="overlay">
        <img
          class="overlay-img"
          v-click-outside="closeOverlay"
          @click="overlay = false"
          :src="overlayimg"
        />
      </v-overlay>
    </div>
  </div>
</template>

<script>
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

export default {
  data() {
    var images = [
      require("../assets/castlecrags.jpg"),
      require("../assets/wine.jpg"),
      require("../assets/serengeti-topout.jpg"),
      require("../assets/ring.jpg"),
      require("../assets/evilution-small.jpg"),
      require("../assets/basslake.jpg"),
      require("../assets/deserteagle.jpg"),
      require("../assets/serengeti.jpg"),
      require("../assets/engage.jpg"),
      require("../assets/roof.jpg"),
      require("../assets/wine-tasting.jpg"),
      require("../assets/garden.jpg"),
      require("../assets/acidwash.jpg"),
      require("../assets/saigon.jpg"),
      require("../assets/joes.jpg"),
      // require("../assets/poudre.jpg"),
      require("../assets/midnight.png"),
      require("../assets/snowboard.jpg"),
      require("../assets/bishop-hero-2.jpg"),
      require("../assets/yosemite.jpg"),
      require("../assets/young.jpg"),
    ];

    return { images: images, overlay: false, overlayimg: "" };
  },
  mounted: function () {
    var grid = document.querySelector(".grid");
    var msnry = new Masonry(grid, {
      // options...
      columnWidth: ".grid-sizer",
      itemSelector: ".grid-item",
      percentPosition: true,
    });

    console.log(msnry);
    imagesLoaded(".grid", function () {
      msnry.layout();
    });
  },
  methods: {
    closeOverlay() {
      if (this.overlay) {
        this.overlay = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  background: white;
  padding: 5px;
  width: 100vw;

  .grid {
    width: 100%;
    // animation: slide 60s linear infinite;

    .grid-sizer,
    .grid-item {
      width: 25%;

      @media screen and (max-width: 650px) {
        width: 50%;
      }
    }
    .grid-item {
      padding: 5px;
      img {
        width: 100%;
      }
    }

    .grid-item.wide {
      width: 50%;
    }
  }

  .overlay-img {
    max-width: 90vw;
    max-height: 90vh;
  }
  img {
    cursor: pointer;
  }
}
</style>