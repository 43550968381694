<template>
  <div id="login">
    <v-container>
      <v-overlay class="overlay" opacity="0">
        <div class="form-content">
          <v-img src="./assets/logo.svg" class="logo"></v-img>
          <p>
            Please enter the password found on your invitation. If you lost your
            invitation or have any questions, please email us at
            help@thewhitakerwedding.com.
          </p>
          <v-text-field
            type="password"
            name="password"
            v-model="password"
            placeholder="Password"
            filled
            background-color="#868d85"
            color="#382c2c"
            v-on:keyup.enter="login"
          ></v-text-field>
          <v-btn type="button" color="#382c2c" @click="login()">Login</v-btn>
          <p v-if="passFailed">Sorry, wrong password.</p>
        </div>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Login",

  components: {},

  data: () => ({ passFailed: false }),
  methods: {
    login() {
      if (this.password == "t&a2022") {
        this.$store.commit("setAuthentication", true);
        this.$router.replace({ name: "home" });
      } else {
        this.passFailed = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  background: #e7dbcf;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  p {
    margin-top: 1rem;
    color: #403730;
  }

  .form-content {
    max-width: 40rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem;
  }

  .logo {
    width: 100%;
  }
}
</style>