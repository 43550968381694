<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <!-- <Hero />
      <Story />

      <v-parallax src="./assets/hero-small.jpg"
        ><v-overlay absolute opacity="0.2"></v-overlay
      ></v-parallax>

      <Party />

      <v-parallax src="./assets/buttermilk-wall.jpg"
        ><v-overlay absolute opacity="0"></v-overlay
      ></v-parallax>

      <Travel />
      <Accommodations />
      <Activities />

      <v-img
        height="800"
        cover
        id="parallax"
        src="./assets/monkeybars.jpg"
      ></v-img>

      <Registry />
      <FAQs />

      <v-img
        height="800"
        cover
        id="parallax"
        src="./assets/evilution-small.jpg"
      ></v-img>

      <RSVP /> -->
    </v-main>
  </v-app>
</template>

<script>
// import Hero from "./components/Hero.vue";
// import Story from "./components/Story.vue";
// import Party from "./components/Party.vue";
// import Travel from "./components/Travel.vue";
// import Accommodations from "./components/Accommodations.vue";
// import Activities from "./components/Activities.vue";
// import Registry from "./components/Registry.vue";
// import FAQs from "./components/FAQs.vue";
// import RSVP from "./components/RSVP.vue";

export default {
  name: "App",

  components: {
    // Hero,
    // Story,
    // Party,
    // Travel,
    // Accommodations,
    // Activities,
    // Registry,
    // FAQs,
    // RSVP,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Crimson Foam";
  src: url("./assets/Crimson\ Foam\ Font.ttf") format("ttf"),
    url("./assets/Crimson\ Foam\ Font.otf") format("otf"),
    url("./assets/Crimson\ Foam\ Font.eot") format("eot"),
    url("./assets/Crimson\ Foam\ Font.woff") format("woff");
}

@font-face {
  font-family: "Wild Mango";
  src: url("./assets/WildMango.ttf") format("ttf"),
    url("./assets/WildMango.otf") format("otf");
}

html {
  word-break: break-word;
}
</style>