<template>
  <div id="faqs">
    <v-container class="faq-container">
      <img class="heading" src="../assets/faqs-text.svg" />
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(question, i) in questions" :key="i">
          <v-expansion-panel-header color="#e7dbcf">{{
            question.q
          }}</v-expansion-panel-header>
          <v-expansion-panel-content color="#e7dbcf"
            >{{ question.a }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h2 class="mt-10">Contact Us</h2>
      <p>
        If you have any questions, please let us know and we’ll get back to you
        as soon as we can!
      </p>

      <v-form
        class="form"
        name="Contact"
        method="POST"
        v-on:submit.prevent="handleSubmit"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="Contact" />

        <v-text-field
          label="Name"
          filled
          background-color="#e7dbcf"
          color="#382c2c"
          name="name"
          v-model="formData.name"
        ></v-text-field>
        <v-text-field
          label="Email"
          filled
          background-color="#e7dbcf"
          color="#382c2c"
          name="email"
          v-model="formData.email"
        ></v-text-field>
        <v-textarea
          filled
          label="Ask a Question"
          background-color="#e7dbcf"
          color="#382c2c"
          name="question"
          v-model="formData.question"
        ></v-textarea>
        <v-btn type="submit">Send</v-btn>
      </v-form>
      <br />
      <p class="success" v-if="formSent">Form Submitted!</p>

      <p>
        For questions on the day of, please contact either Sandy Whitaker at
        (559) 474-1895 or Martha Anenberg at (949) 510-2836.
      </p>
      <v-img src="../assets/moms.jpg"></v-img>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    let questions = [
      {
        q: "Where am I going? What time do I need to be there?",
        a: "Our ceremony will take place at 5pm at The Whitaker Family Residence: 15306 Mark Road, Madera, CA 93636. For info on your stay, please visit our travel page.",
      },
      {
        q: "What will the weather be like? What should I wear?",
        a: "Madera averages a high of 83F and a low of 52F during the month of May. The venue will be outdoors so a light jacket at night might be comfortable. While most of the grounds are paved, there are some gravely areas, so stiletto heels are okay but not recommended. The dress code is semi-formal/cocktail attire (think: cocktail dresses, dressy jumpsuits, button down, blazers, suit and tie)",
      },
      {
        q: "Will there be parking available?",
        a: "Yes, we will have parking on site however please carpool if possible. Additional street parking is allowed.",
      },
      {
        q: "Will there be drinks?",
        a: "Uh yes! There will be an open bar. That being said, we care about your safety and encourage you to Uber/Lyft or coordinate a designated driver for a responsible night. If you need to leave your car overnight, that’s fine!",
      },
      {
        q: "Will you be accommodating any diet restrictions?",
        a: "We will accommodate vegetarian meals upon request. Please leave a comment on your rsvp. If you have any dietary concerns, please feel free to let us know!",
      },
      {
        q: "Are children allowed?",
        a: "Yes! While our event will be mainly adults, children are invited. If they are not listed on your RSVP, please let us know in the comments so we can account for them in our planning. There will be alcohol as well as an open pool at the venue, so please keep a close watch on your children.",
      },
      {
        q: "Can I bring a date?",
        a: "If your date is named on your invitation, yes! If not, we will be sure to seat you with people you know and love. If you have any questions or concerns, please let us know.",
      },
      {
        q: "What about Covid-19?",
        a: "Our event will be fully outdoors. We kindly ask that if you are not vaccinated that you wear a mask and take a rapid test before arriving. We’ll be following CDC and State recommendations, and will continue to update our page if updates occur.",
      },
      {
        q: "When should I RSVP by?",
        a: "Please RSVP on our website by April 30, 2022. Visit the RSVP page.",
      },
    ];

    return { questions: questions, formData: {}, formSent: false };
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    },
    handleSubmit(e) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": e.target.getAttribute("name"),
          ...this.formData,
        }),
      })
        .then(() => (this.formSent = true))
        .catch((error) => alert(error));
    },
  },
};
</script>

<style lang="scss" scoped>
#faqs {
  padding: 5rem 0;
  color: #fffbf6;
  background: #868d85;

  .faq-container {
    max-width: 50rem;
  }
  h1 {
    text-align: center;
    padding-bottom: 5rem;
    font-family: "Wild Mango" !important;
    font-size: 4rem !important;
    color: #e7dbcf;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  }
  .heading {
    padding-bottom: 5rem;
    margin: 0 auto;
    display: block;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
    max-width: 150px;
    width: 90vw;
  }

  .v-btn {
    background-color: #e7dbcf !important;
    color: #403730 !important;
  }

  .form {
    textarea {
      background: #e7dbcf;
    }
  }

  .v-expansion-panel-header {
    color: #403730;
    font-weight: bold;
  }
  .v-expansion-panel-content {
    font-size: 0.85rem;
  }
}
</style>