<template>
  <div id="travel">
    <div class="map">
      <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center="center"
        :zoom="zoom"
      >
        <MglMarker :coordinates="center" color="green" />
      </MglMap>
    </div>
    <div class="description">
      <div class="content">
        <h1 class="title">Travel</h1>

        <p>
          The Whitaker Residence is located in the Bonadelle Madera Ranchos. It
          is 2 ½ hours south of the Bay Area and 5 hours north of Orange County.
          There will be onsite parking available, but please carpool if
          possible.
        </p>

        <p>
          If you’re flying in, our venue is only 25 minutes from the Fresno
          Yosemite International Airport (FAT)
        </p>
        <v-btn href="https://flyfresno.com/" color="white">Find Flights</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";

export default {
  components: {
    MglMap,
    MglMarker,
  },
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoidG13aHRrciIsImEiOiJja2x2NzdpaW0wNXRnMndwOGszNTc3aWd5In0.LvJ2znCQ_1v9a86fxUhQ2A",
      mapStyle: "mapbox://styles/mapbox/navigation-day-v1",
      center: [-119.891107, 36.971032],
      zoom: 11,
    };
  },

  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },
};
</script>

<style lang="scss" scoped>
#travel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #fffbf6;
  background-color: #868d85;

  .map {
    flex: 0 0 50%;
    height: 65vh;
    width: 100%;

    @media screen and (max-width: 650px) {
      flex: 0 0 100%;
    }
  }
  .description {
    flex: 0 0 50%;

    .content {
      padding: 1rem;
      .title {
        font-family: "Playfair Display" !important;
        font-size: 3rem !important;
        padding-bottom: 2rem;
        @media screen and (max-width: 450px) {
          font-size: 2rem !important;
        }
      }
      max-width: 40rem;
      margin: 0 auto;

      .v-btn {
        background-color: #e7dbcf !important;
        color: #382c2c !important;
      }
    }

    @media screen and (max-width: 650px) {
      flex: 0 0 100%;

      .content {
        padding: 2rem;
      }
    }
  }
}
</style>