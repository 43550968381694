<template>
  <div id="activities">
    <div class="intro">
      <h1 class="title">Things to Do</h1>
      <p>
        If you’re planning on staying for the weekend, here’s some fun things to
        do
      </p>
    </div>

    <v-container class="d-flex flex-wrap" style="max-width: 75rem">
      <div class="col">
        <h3>If you have half a day…</h3>
        <ul>
          <li>
            <a href="https://shopriverpark.com/"
              >River Park Outdoor Shopping Mall</a
            >. Here you can spend hours shopping, dining out, or even catching a
            movie. If you visit on a Saturday morning, swing by from 10am to 2pm
            for the Farmer’s Market.
          </li>
          <li>
            <a href="https://www.maderawinetrail.com/">Madera Wine Trail</a>.
            We’re always down to go wine tasting! If you want to relax on the
            patio with a glass of wine, we recommend Toca Madera or Birdstone
            Winery. For a great port (best in class, 100 pts!) definitely visit
            Ficklin Vineyards.
          </li>

          <li>
            <a href="https://www.google.com/search?q=bass+lake">Bass Lake</a>. A
            beautiful spot for an afternoon away! You can spend the day
            kayaking, fishing, hiking, or just grab a bite to eat and enjoy the
            views.
          </li>
          <li>
            <a href="https://www.fresnochaffeezoo.org/">Fresno Chaffee Zoo</a>.
            A hidden gem. This zoo packs in a surprising amount of exhibits. We
            loved the tropical rainforest and the sea lion cove.
          </li>

          <li>
            <a href="http://www.undergroundgardens.com/"
              >The Forestiere Underground Gardens</a
            >. This one’s a pretty unique experience. A California Historical
            Landmark built way back in 1906. Make reservations for a guided tour
            and explore the underground network of gardens, tunnels, and
            courtyards.
          </li>
        </ul>
      </div>

      <div class="col">
        <h3>If you have a full day…</h3>
        <ul>
          <li>
            <a href="https://www.nps.gov/yose/index.htm"
              >Yosemite National Park</a
            >. One of our favorite places in the world! If you’re down for an
            adventure and want to get outdoors to hike, climb, or sightsee, then
            this is perfect for you. The Whitaker Residence is only 2 hours from
            Yosemite Valley.
          </li>

          <li>
            <a href="https://www.nps.gov/seki/index.htm"
              >Sequoia and Kings Canyon National Parks</a
            >. Another favorite! Visit the Giant Sequoias, hike Big Trees Trail,
            or swim at Muir Rock. The Whitaker Residence is a little over 2
            hours from the park entrance.
          </li>
        </ul>
      </div>

      <div class="col">
        <h3>Places to eat...</h3>
        <ul>
          <li>
            <a href="https://www.heirloom-eats.com/">Heirloom</a>. Seasonal
            farm-to-table meals with veggie options are served in this airy,
            laid-back spot.
          </li>
          <li>
            <a href="https://www.axistesoro.com/">Axis Coffee Bar & Eatery</a>.
            This is the perfect spot for a lunch, cold beer, or hot coffee in
            the morning. Axis has an awesome patio with a great atmosphere.
          </li>
          <li>
            <a href="https://www.pismos.com">Pismo's Coastal Grill</a>. American
            seafood menu is served in a lodgelike eatery with a bar & separate
            seafood deli counter.
          </li>
          <li>
            <a href="https://el-rinconcito-mexican-grill.business.site"
              >El Rinconcito</a
            >. This is a small family owned restaurant in Madera Ranchos. Really
            good authentic mexican food.
          </li>
          <li>
            <a href="https://www.sushidaymenu.com/">Sushi Day</a>. The local
            sushi restaurant in Fresno. They have a giant selection of sushi
            rolls at great prices.
          </li>
          <li>
            <a href="https://www.namijapanesecuisine.com/"
              >Nami Japanese Cuisine</a
            >. Nami has amazing and delicious Teppanyaki. Come here for an
            experience as your chef cooks on a giant iron griddle right in front
            of you.
          </li>
        </ul>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#activities {
  padding: 5rem 0;
  color: #fffbf6;
  background: #868d85;

  .title {
    font-family: "Playfair Display" !important;
    font-size: 3rem !important;
    padding-bottom: 2rem;
    @media screen and (max-width: 450px) {
      font-size: 2rem !important;
    }
  }

  .intro {
    text-align: center;
    padding: 0 12px;
  }
  .description {
  }

  .col {
    flex: 0 0 33%;

    @media screen and (max-width: 650px) {
      flex: 0 0 100%;
    }
  }
  a {
    color: white;
    font-weight: bold;
  }
}
</style>