<template>
  <div>
    <Header />

    <Hero />
    <Story />

    <v-parallax src="./assets/hero-small.jpg"
      ><v-overlay absolute opacity="0.2"></v-overlay
    ></v-parallax>

    <Party />

    <v-parallax src="./assets/buttermilk-wall.jpg"
      ><v-overlay absolute opacity="0"></v-overlay
    ></v-parallax>

    <Travel />
    <Accommodations />
    <Activities />

    <Gallery />

    <Registry />
    <FAQs />

    <RSVP />
    <Thanks />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Hero from "./components/Hero.vue";
import Story from "./components/Story.vue";
import Party from "./components/Party.vue";
import Travel from "./components/Travel.vue";
import Accommodations from "./components/Accommodations.vue";
import Activities from "./components/Activities.vue";
import Gallery from "./components/Gallery.vue";
import Registry from "./components/Registry.vue";
import FAQs from "./components/FAQs.vue";
import RSVP from "./components/RSVP.vue";
import Thanks from "./components/Thanks.vue";

export default {
  name: "Home",

  components: {
    Header,
    Hero,
    Story,
    Party,
    Travel,
    Accommodations,
    Activities,
    Gallery,
    Registry,
    FAQs,
    RSVP,
    Thanks,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
</style>