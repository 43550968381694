<template>
  <div id="accommodations">
    <div class="description">
      <div class="content">
        <h1 class="title">Accommodations</h1>
        <p>
          <!-- We recommend staying in the River Park area in Northern Fresno. More -->
          <!-- info on hotel blocks will be posted in January. -->

          We recommend staying in the River Park area in Northern Fresno. We
          have reserved a block of rooms at the Hyatt Place Fresno <link /> and
          at the Hampton Inn and Suites <link /> for the night of May 21, 2022.
        </p>
        <ul>
          <li>
            <a
              href="https://www.hyatt.com/en-US/hotel/california/hyatt-place-fresno/fchzn?src=corp_lclb_gmb_seo_fchzn"
              >Hyatt Place Fresno</a
            >
            <p>
              7333 N Fresno St, Fresno, California, 93720 <br />
              One-King Room Block Rate: $231.12 <br />
              Two-Queen Room Block Rate: $251.12 <br />
              If booking online, use group code <strong>G-WHWD</strong>. If
              booking by phone, mention the Anenberg Whitaker Wedding group. The
              final day to book rooms using the discounted rate is April 21,
              2022.
            </p>
          </li>
          <li>
            <a
              href="https://www.hilton.com/en/hotels/fatcahx-hampton-suites-fresno/?SEO_id=GMB-HX-FATCAHX&y_source=1_MjA4MzcwOC03MTUtbG9jYXRpb24uZ29vZ2xlX3dlYnNpdGVfb3ZlcnJpZGU%3D"
              >Hampton Inn & Suites</a
            >
            <p>
              327 E Fir Ave, Fresno, California, 93720 <br />
              One-King Room Block Rate: $169.00 <br />
              Two-Queen Room Block Rate: $169.00 <br />
              To book, please call (559) 447-5900 and mention the Anenberg
              Whitaker Wedding group for the night of May 21st. The final day to
              book rooms using the discounted rate is May 14, 2022.
            </p>
          </li>
        </ul>

        <p>
          Two additional options in the area include the
          <a
            href="https://www.wyndhamhotels.com/laquinta/fresno-california/la-quinta-fresno-riverpark/overview?CID=LC:LQ::GGL:RIO:National:52801&iata=00093796"
            >La Quinta Inn & Suites</a
          >
          and the
          <a
            href="https://www.ihg.com/holidayinnexpress/hotels/us/en/fresno/fatca/hoteldetail?cm_mmc=GoogleMaps-_-EX-_-US-_-FATCA"
            >Holiday Inn Express & Suites</a
          >.
        </p>

        <p>
          If you prefer the coziness of a home, then there are many
          <a href="https://www.airbnb.com">AirBnb</a> and
          <a href="https://www.vrbo.com">VRBO</a> listings in the area that we
          definitely recommend checking out! This is a particularly fun option
          for groups traveling together.
        </p>
      </div>
    </div>
    <div class="photo">
      <img
        src="https://images.unsplash.com/photo-1616486029423-aaa4789e8c9a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1632&q=80"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#accommodations {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #e7dbcf;
  color: #382c2c;

  .description {
    flex: 0 0 50%;

    .content {
      padding: 1rem;
      .title {
        font-family: "Playfair Display" !important;
        font-size: 3rem !important;
        padding-bottom: 2rem;

        @media screen and (max-width: 450px) {
          font-size: 2rem !important;
        }
      }

      ul {
        padding-left: 0 !important;
        li {
          list-style: none;
          a {
            font-size: 1.2rem;
            font-weight: bold;
            color: #376797;
            &:hover {
              color: #1c3248;
            }
          }
        }
      }
      max-width: 40rem;
      margin: 0 auto;
    }

    @media screen and (max-width: 750px) {
      flex: 0 0 100%;
      order: 2;
      .content {
        padding: 2rem;
      }
    }
  }
  .photo {
    align-self: stretch;
    flex: 0 0 50%;

    img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 750px) {
      flex: 0 0 100%;
      order: 1;
    }
  }
}
</style>