<template>
  <div id="hero">
    <v-parallax
      height="800"
      src="../assets/blurry-small-cartoon-crop.jpg"
      cover
      class="d-"
    >
      <v-overlay value="true" absolute opacity="0.2" class="text-overlay">
        <v-img class="hero-text" src="../assets/hero-text.svg"></v-img>
        <br /><br />
        <p>
          We've decided to fulfill our rock climber fantasy of getting married
          in the mountains! As such, we will be eloping to a meaningful and
          remote location in The Eastern Sierra.
        </p>
        <p>
          However, we want our family and friends to be a part of this special
          time in our lives. So please join us for a wedding celebration where
          we will have an abridged ceremony and dinner, drinks, and dancing!
        </p>
        <p class="uppercase">
          Saturday, May 21, 2022 <br />
          five o'clock in the afternoon <br />
          ceremony to begin at 5:15 pm
        </p>
        <p class="uppercase">
          The Whitaker Residence <br />
          15306 Mark Road <br />
          Madera Ranchos, CA
        </p>
      </v-overlay>
    </v-parallax>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#hero {
  text-align: center;

  .hero-text {
    max-width: 750px;
    width: 95vw;
    margin: 0 auto;
  }

  p {
    letter-spacing: 0.1rem;
    font-size: 0.85rem;
    color: #fffaf5;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
  .text-overlay p {
    max-width: 50rem;
  }
  .uppercase {
    text-transform: uppercase;
  }
}
</style>
<style>
/* #parallax .v-parallax__image-container img.v-parallax__image {
  width: 100% !important;
} */
.hero-text .v-image__image,
.hero-text .v-image__image--cover {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
}
.v-overlay__content {
  padding: 1rem;
}
</style>